import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';

const SEO = ({ title, description, keywords, image }) => {
    const location = useLocation();
    const canonicalUrl = `https://thinail.com${location.pathname}`;

    return (
        <Helmet>
            <title>{title}</title>
            <meta name="description" content={description} />
            <meta name="keywords" content={keywords} />
            <link rel="canonical" href={canonicalUrl} />

            {/* Open Graph Meta Tags */}
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
            <meta property="og:image" content={image || "https://thinail.com/nail-exam-og.jpg"} />
            <meta property="og:url" content={canonicalUrl} />

            {/* Twitter Meta Tags */}
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="robots" content="index, follow" />

            {/* JSON-LD Structured Data */}
            <script type="application/ld+json">
                {JSON.stringify({
                    "@context": "https://schema.org",
                    "@type": "WebPage",
                    "name": title,
                    "description": description,
                    "publisher": {
                        "@type": "Organization",
                        "name": "Luyện Thi Nail Online",
                        "logo": {
                            "@type": "ImageObject",
                            "url": "https://thinail.com/Logo-Thinail.png"
                        }
                    }
                })}
            </script>
        </Helmet>
    );
};

export default SEO;
