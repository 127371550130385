import React, { useEffect } from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import { HelmetProvider } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';
import './homePage.css';
import SEO from '../../components/Tools/SEO';
import nailExamImage from '../../assets/nail-exam.jpg';

const HomePage = () => {
    const navigate = useNavigate();

    useEffect(() => {
        AOS.init({
            duration: 1000,
            once: true,
        });
    }, []);

    const handleStartQuiz = () => {
        navigate('/quizzes');
    };

    return (
        <HelmetProvider>
            {/* SEO Meta Tags */}
            <SEO
                title="Đề Thi Nail California 2025 | Luyện Thi Bằng Nail Tiếng Việt Mới Nhất"
                description="Luyện thi bằng nail với đề thi mới nhất năm 2025. Cập nhật đề thi nail California 2025 tiếng Việt, trắc nghiệm nail với 60 câu hỏi."
                keywords="đề thi nail california 2025, thi nail tiếng việt, câu hỏi thi nail mới nhất, luyện thi nail online, trắc nghiệm nail 60 câu, kỳ thi PSI nail, bằng nail California, thi nail 2025"
                image="https://thinail.com/nail-exam-og.jpg"
            />

<Container className="homepage-container mb-5">
                {/* Hero Section */}
                <section className="hero-section">
                    <Row className="align-items-center homepage-hero">
                        <Col md={6} className="text-md-left" data-aos="fade-right">
                            <h1 className="homepage-title mb-4">Luyện Thi Chứng Chỉ Nail California 2025</h1>
                            <p className="lead mb-4">Chuẩn bị cho kỳ thi PSI với bộ <strong>đề thi nail tiếng Việt mới nhất 2025</strong>. Tổng hợp trắc nghiệm chuẩn từ California và toàn Mỹ.</p>
                            <Button variant="primary" size="lg" className="mb-4" onClick={handleStartQuiz} aria-label="Bắt đầu luyện thi nail">
                                Bắt Đầu Luyện Tập Ngay
                            </Button>
                        </Col>
                        <Col md={6} data-aos="fade-left">
                            <img
                                src={nailExamImage}
                                loading="lazy"
                                alt="Đề thi nail California 2025 tiếng Việt"
                                className="img-fluid rounded homepage-image"
                                width="600"
                                height="400"
                            />
                        </Col>
                    </Row>
                </section>

                {/* Feature Cards */}
                <section className="features-section" aria-labelledby="features-heading">
                    <h2 id="features-heading" className="visually-hidden">Tính năng luyện thi nail</h2>
                    <Row className="my-5">
                        <Col md={4} data-aos="zoom-in">
                            <Card className="h-100 homepage-card">
                                <Card.Body>
                                    <Card.Title as="h3">Đề Thi Nail Mới Nhất 2025</Card.Title>
                                    <Card.Text>Cập nhật liên tục các đề thi nail từ California và các tiểu bang khác. Bám sát cấu trúc PSI.</Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={4} data-aos="zoom-in" data-aos-delay="200">
                            <Card className="h-100 homepage-card">
                                <Card.Body>
                                    <Card.Title as="h3">Dễ Dàng Luyện Tập Online</Card.Title>
                                    <Card.Text>Truy cập vào các bài thi trắc nghiệm nail tiếng Việt để nâng cao khả năng ghi nhớ và chuẩn bị cho kỳ thi.</Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={4} data-aos="zoom-in" data-aos-delay="400">
                            <Card className="h-100 homepage-card">
                                <Card.Body>
                                    <Card.Title as="h3">Thi Thử Mô Phỏng PSI</Card.Title>
                                    <Card.Text>Thực hiện các bài thi thử nail mô phỏng theo cấu trúc của PSI, 60 câu trong 90 phút, dễ dàng làm quen.</Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </section>

                {/* Exam Details */}
                <section className="exam-details-section" aria-labelledby="exam-details-heading">
                    <Row className="my-5">
                        <Col data-aos="fade-up">
                            <h2 id="exam-details-heading" className="homepage-section-title">Câu Hỏi Thi Nail Tiếng Việt Mới Nhất 2025</h2>
                            <p className="lead mb-4">
                                Chuẩn bị cho kỳ thi chứng chỉ với <strong>đề thi nail California 2025</strong> và <strong>câu hỏi thi nail tiếng Việt mới nhất 2025</strong>.
                                Luyện tập với các đề thi được cập nhật theo quy định mới.
                            </p>
                            <p className="homepage-text">
                                Thi lý thuyết nails là yêu cầu bắt buộc để được cấp bằng hành nghề làm nails tại California. Sau khi hoàn thành khóa học 400 giờ, bạn phải vượt qua bài kiểm tra viết để được cấp bằng. Bài thi gồm 60 câu hỏi trắc nghiệm, thời gian làm bài 90 phút.
                            </p>
                            <p className="homepage-text">
                                <strong>Cập nhật mới nhất:</strong> Có hiệu lực từ ngày 1 tháng 1 năm 2022, kỳ thi thực hành không còn bắt buộc đối với tất cả các loại giấy phép. Các ứng viên chỉ cần vượt qua kỳ thi viết để được cấp phép hành nghề nail tại California.
                            </p>
                        </Col>
                    </Row>
                </section>

                {/* Exam Structure */}
                <section className="exam-structure-section" aria-labelledby="exam-structure-heading">
                    <Row className="my-5">
                        <Col data-aos="fade-up" data-aos-delay="300">
                            <h2 id="exam-structure-heading" className="homepage-section-title">Cấu Trúc Đề Thi Lý Thuyết Nail California 2025</h2>
                            <div className="homepage-list">
                                <ul>
                                    <li><strong>Thời gian thi:</strong> 90 phút</li>
                                    <li><strong>Số lượng câu hỏi:</strong> 60 câu trắc nghiệm</li>
                                    <li><strong>Ngôn ngữ:</strong> Có sẵn bản tiếng Việt chính thức</li>
                                    <li><strong>Điểm đỗ tối thiểu:</strong> 75%</li>
                                </ul>
                            </div>
                            <p>
                                Đề thi tiếng Việt có nhiều câu dịch khá khó hiểu, mời bạn tham khảo thêm{' '}
                                <a
                                    href="https://proctor2.psionline.com/media/programs/CADCA/Vietnamese.pdf"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="psi-link"
                                    aria-label="Tải tài liệu thuật ngữ nail từ PSI"
                                >
                                    một số thuật ngữ do PSI cung cấp tại đây
                                </a>.
                            </p>

                        </Col>
                    </Row>
                </section>
            </Container>
        </HelmetProvider>
    );
};

export default HomePage;
